import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import { UserContext } from "../contexts/UserProvider";
import { fonts, colors } from "./Theme";
import { useAuth } from "../contexts/AuthContext";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function Footer(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const { currentUser } = useAuth();

  return (
    <div>
      <Card
        style={{
          borderTop: "1px solid grey",
          textAlign: "center",
          borderRadius: "0px",
          opacity: "98%",
        }}
      >
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={"center"}
            flexWrap={"wrap"}
            gap="10px"
          >
            <>
              <img
                src={ state.darkModeEnabled ? state.branding.logoDarkURL : state.branding.logoURL}
                alt="Logo"
                style={{
                  width: "100%",
                  maxWidth: "800px",
                }}
              />
            </>
            <Box style={{ paddingLeft: "10px", paddingTop: "10px" }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Button style={{ minHeight: "50px" }}>
                  <a
                    href={`mailto:${state.serviceData.contactEmail}?subject=Router Panel, Account email: ${currentUser.email}`}
                    style={{ textDecoration: "none" }}
                  >
                    <MailOutlineIcon
                      style={{
                        color: state.darkModeEnabled
                          ? colors.lightColor1
                          : colors.darkColor1,
                        marginRight: "10px",
                        paddingTop: "8px",
                      }}
                    ></MailOutlineIcon>
                  </a>
                  <a
                    href={`mailto:${state.serviceData.contactEmail}?subject=Router Panel, Account email: ${currentUser.email}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      style={{
                        color: state.darkModeEnabled
                          ? colors.lightColor1
                          : colors.darkColor1,
                        textTransform: "none",
                      }}
                    >
                      Email support: {state.serviceData.contactEmail}
                    </Typography>
                  </a>
                </Button>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Button
                  style={{ minHeight: "50px", paddingRight: "145px" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${state.serviceData.contactNumber}`
                    );
                  }}
                >
                  <ContentCopyIcon
                    style={{
                      color: state.darkModeEnabled
                        ? colors.lightColor1
                        : colors.darkColor1,
                      marginRight: "10px",
                    }}
                  ></ContentCopyIcon>
                  <Typography
                    style={{
                      color: state.darkModeEnabled
                        ? colors.lightColor1
                        : colors.darkColor1,
                      textTransform: "none",
                    }}
                  >
                    Telephone: {state.serviceData.contactNumber}
                  </Typography>
                </Button>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "left",
                }}
              >
                <Button
                  style={{
                    paddingRight: "245px",
                    minHeight: "50px",
                  }}
                  onClick={props.handleLogout}
                >
                  <LogoutIcon
                    style={{
                      color: state.darkModeEnabled
                        ? colors.lightColor1
                        : colors.darkColor1,
                    }}
                  ></LogoutIcon>

                  <Typography
                    style={{
                      paddingLeft: "15px",
                      color: state.darkModeEnabled
                        ? colors.lightColor1
                        : colors.darkColor1,
                      textTransform: "none",
                    }}
                  >
                    Sign out
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card style={{opacity: "98%"}}>
      <div style={{padding:"10px", opacity: "98%",borderTop:"1px solid grey"}}>
            {" "}
            {/* Footer */}
            <Box
              display={"flex"}
              gap={"15px"}
              justifyContent={"center"}
              alignItems={"center"}
              >
              <Typography style={{paddingRight: "5px", fontSize: "10px"}}>Powered by</Typography>
              <img src={state.darkModeEnabled ? "./hbs_white.png": "./hbs_blue.png"} style={{ height: "15px" }}></img>
            </Box>
          </div>
          </Card>

    </div>
  );
}

export default Footer;
