import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import CenteredTabs from "./CenteredTabs";
import { UserContext } from "../contexts/UserProvider";
import { db } from "../firebase";
import ErrorSnackbar from "./ErrorSnackbar";
import Footer from "./Footer";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { colors, fonts } from "./Theme";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@material-ui/core";

export default function Dashboard() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { currentUser, logout, getToken } = useAuth();
  const history = useHistory();
  const [state, dispatch] = React.useContext(UserContext);
  const stateRef = useRef();
  stateRef.current = state;

  useEffect(() => {
    dispatch({ type: "loading", value: true });
    document.body.style.overflowY = "scroll";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";

    const fbListener = subscribeToUserDB();
    return () => {};
  }, []);

  const theme = createTheme({
    palette: {
      type: state.darkModeEnabled ? "dark" : "light",
      primary: {
        main: state.darkModeEnabled ? colors.danger1 : colors.darkColor1,
      },
      background: {
        paper: state.darkModeEnabled ? "#2d2e28" : colors.lightColor1,
      },
      action: {
        disabledBackground: "#B6B5B2",
        disabled: colors.darkColor1,
      },
      error: {
        main: colors.danger1,
      },
    },

    typography: {
      fontFamily: fonts.medium,
    },
  });

  function checkingCurrentSelection() {
    let currentSelecDest = stateRef.current.currentSelecDest;
    stateRef.current.firebaseData.Destinations.forEach((destination, index) => {
      if (destination.DestinationNumber == currentSelecDest.DestinationNumber) {
        if (true) {
          if (
            currentSelecDest.CurrentSourceNumber !=
            destination.CurrentSourceNumber
          ) {
            dispatch({
              type: "selectDestination",
              value: destination,
              value2: index,
            });
          }
        }
      }
    });
  }

  async function subscribeToUserDB() {
    var streamingData = db.collection("global").doc("streamingData");
    var serviceData = db.collection("global").doc("serviceData");
    var branding = db.collection("global").doc("branding");

    const distributionsDataListener = db
      .collection("global")
      .doc("distributions")
      .onSnapshot((doc) => {
        let fbDistroData = doc.data();
        dispatch({
          type: "addDistributions",
          value: fbDistroData.Distributions,
        });
      });

    const firebaseListener = db
      .collection("users")
      .doc(currentUser.uid)
      .onSnapshot((doc) => {
        let fireBaseData = doc.data();
        var sourceGroupsInfo = db.collection("global").doc("sourceGroups");
        sourceGroupsInfo
          .get()
          .then((doc) => {
            if (doc.exists) {
              let fbData = doc.data();
                // Check if the data for the specified sourceGroup exists and is not null
                if (fbData[fireBaseData.sourceGroup] !== undefined && fbData[fireBaseData.sourceGroup] !== null) {
                  dispatch({
                    type: "updateAvailableSources",
                    value: fbData[fireBaseData.sourceGroup],
                  });
                }else {
                  console.error("Source Group does not seem to exist")
                }
            }
          })
          .catch((error) => {console.error(error);
          });

        streamingData
          .get()
          .then((doc) => {
            if (doc.exists) {
              let fbStreamingData = doc.data();
              dispatch({
                type: "updateStreamingData",
                value: fbStreamingData,
              });
            }
          })
          .catch((error) => {
            console.error(
              "Error getting document: Global Streaming Data",
              error
            );
          });

        branding
          .get()
          .then((doc) => {
            if (doc.exists) {
              let brandingData = doc.data();
              colors.lightColor1 = brandingData.colors.lightColor1;
              colors.lightColor2 = brandingData.colors.lightColor2;
              colors.mediumColor = brandingData.colors.mediumColor1;
              colors.darkColor1 = brandingData.colors.darkColor1;
              colors.darkColor2 = brandingData.colors.darkColor2;
              colors.mediumAlert1 = brandingData.colors.alertColor1;
              colors.mediumAlert2 = brandingData.colors.alertColor2;
              colors.danger1 = brandingData.colors.dangerColor1;
              dispatch({
                type: "addBranding",
                value: brandingData,
              });
              document.body.style.backgroundImage = `url(${brandingData.backgroundURL})`;
            }
          })
          .catch((error) => {
            console.error(
              "Error getting document: Global Streaming Data",
              error
            );
          });

        serviceData
          .get()
          .then((doc) => {
            if (doc.exists) {
              let fbServiceData = doc.data();
              dispatch({
                type: "updateServiceData",
                value: fbServiceData,
              });
            }
          })
          .catch((error) => {
            console.error("Error getting document: Service Data", error);
          });

        var routingManagerData = db.collection("global").doc("routingManager");
        routingManagerData
          .get()
          .then((doc) => {
            if (doc.exists) {
              let fbRoutingManagerData = doc.data();
              dispatch({
                type: "updateRoutingManagerAddress",
                value: fbRoutingManagerData.routingManagerAddress,
              });
              dispatch({
                type: "updateRoutingManagerPort",
                value: fbRoutingManagerData.routingManagerPort,
              });
              dispatch({
                type: "updateRoutingManagerAPIKey",
                value: fbRoutingManagerData.routingManagerAPIKey,
              });
            } else {
              console.error("No such document ::: Routing Manager!");
            }
          })
          .catch((error) => {
            console.error(
              "Error getting document: Global Streaming Data",
              error
            );
          });

        dispatch({ type: "updateFirebaseData", value: fireBaseData });
        checkingCurrentSelection();
        dispatch({
          type: "updateControllerBindings",
          value: fireBaseData.ControllerBindings,
        });
        dispatch({
          type: "updateSourceGroup",
          value: fireBaseData.sourceGroups,
        });
        dispatch({
          type: "updateGloballyLocked",
          value: fireBaseData.globallyLocked,
        });
        dispatch({
          type: "selectRegion",
          value: fireBaseData.region,
        });

        dispatch({
          type: "updateDarkModeGlobal",
          value: fireBaseData.darkModeGlobal,
        });

        if (fireBaseData.darkModeGlobal) {
          dispatch({
            type: "enableDarkmode",
          });
        }

        dispatch({ type: "loading", value: false });
      });

    const sourceGroupsInfoListener = db
      .collection("global")
      .doc("sourceGroups")
      .onSnapshot((doc) => {
        let sourceGroupsData = doc.data();
        var userData = db.collection("users").doc(currentUser.uid);
        userData
          .get()
          .then((doc) => {
            if (doc.exists) {
              let userDataInfo = doc.data();
              // Check if the data for the specified sourceGroup exists and is not null
              if (sourceGroupsData[userDataInfo.sourceGroup] !== undefined && sourceGroupsData[userDataInfo.sourceGroup] !== null) {
                dispatch({
                  type: "updateAvailableSources",
                  value: sourceGroupsData[userDataInfo.sourceGroup],
                });
              } else {
                console.error("Source Group does not seem to exist")
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });

    setTimeout(() => {
      setLoading(false);
    }, 1500);
    return firebaseListener;
  }

  async function handleLogout() {
    setError("");
    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  function handleErrors(response) {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  }

  const createTakeRequest = async (SourceName, SourceNumber, SourceFormat) => {
    dispatch({ type: "toggle_ErrorSnackbar", value: false });
    if (
      typeof SourceName != "undefined" &&
      typeof SourceNumber != "undefined" &&
      typeof state.currentSelecDest.DestinationAlias != "undefined" &&
      typeof state.currentSelecDest.DestinationNumber != "undefined" &&
      state.currentSelecDest.Format == SourceFormat
    ) {
      dispatch({ type: "waitingOnRouteManager", value: true });
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "Application/json" },
      };
      const routingManagerAddress = state.routingManagerAddress;
      const routingManagerPort = state.routingManagerPort;
      const currentToken = await currentUser.getIdToken();
      const sourceName = encodeURIComponent(SourceName);
      const destinationAlias = encodeURIComponent(
        state.currentSelecDest.DestinationAlias
      );
      fetch(
        `https://${routingManagerAddress}:${routingManagerPort}/reqRouterChange?destinationName=${destinationAlias}&destinationNumber=${state.currentSelecDest.DestinationNumber}&sourceName=${sourceName}&sourceNumber=${SourceNumber}&userToken=${currentToken}&userAlias=${state.firebaseData.userAlias}&apiKey=${state.routingManagerApiKey}`,
        requestOptions
      )
        .then(handleErrors)
        .then((response) => response.json())
        .catch((error) => () => {
          dispatch({
            type: "set_ErrorSnackbarMessage",
            value: "Request to Router failed.",
          });
          dispatch({ type: "toggle_ErrorSnackbar", value: true });
          dispatch({ type: "waitingOnRouteManager", value: false });
        })
        .then((data) => {
          if (data.success) {
            dispatch({ type: "waitingOnRouteManager", value: false });
          } else {
            dispatch({
              type: "set_ErrorSnackbarMessage",
              value: `Request to Router failed for ${SourceName}.`,
            });
            dispatch({ type: "toggle_ErrorSnackbar", value: true });
          }
        })
        .then(() => {
          dispatch({ type: "waitingOnRouteManager", value: false });
        });
    } else if (
      typeof SourceName == "undefined" ||
      typeof SourceNumber == "undefined" ||
      typeof state.currentSelecDest.DestinationAlias == "undefined" ||
      typeof state.currentSelecDest.DestinationNumber == "undefined"
    ) {
      dispatch({
        type: "set_ErrorSnackbarMessage",
        value: "Please select source and destination.",
      });
      dispatch({ type: "waitingOnRouteManager", value: false });
      dispatch({ type: "toggle_ErrorSnackbar", value: true });
    } else if (state.currentSelecDest.Format !== SourceFormat) {
      dispatch({
        type: "set_ErrorSnackbarMessage",
        value:
          "Format Error - Please select source and destination of same format.",
      });
      dispatch({ type: "waitingOnRouteManager", value: false });
      dispatch({ type: "toggle_ErrorSnackbar", value: true });
    }
  };

  function handleKeyUp(event) {
    let pressedKey = event.key;

    let bindedItemForKey = returnBindingItemForKey(pressedKey);
    if (bindedItemForKey !== "undefined") {
      triggerKeyAction(bindedItemForKey);
    }
  }

  function returnBindingItemForKey(keyID) {
    let bindingItem = "undefined";
    state.controllerBindings.forEach((element, index) => {
      if (element.keyID == keyID) {
        bindingItem = element;
      }
    });

    return bindingItem;
  }

  function triggerKeyAction(item) {
    if (item.keyType == "globalLock") {
      changeGlobalLockStatus();
    } else if (item.keyType == "lock" && !state.globallyLocked) {
      dispatch({ type: "lockRouterPanel" });
    } else if (!state.globallyLocked && !state.panelLocked) {
      if (item.keyType == "source" && !state.globallyLocked) {
        let source = state.availableSources[item.bindingIndex];
        dispatch({ type: "selectSource", value: source });
        if (!state.takeButtonEnabled) {
          createTakeRequest(
            source.SourceName,
            source.SourceNumber,
            source.Format
          );
        }
      } else if (item.keyType == "destination" && !state.globallyLocked) {
        dispatch({ type: "selectDestination", value2: item.bindingIndex });
      } else if (item.keyType == "take" && !state.globallyLocked) {
        createTakeRequest(
          state.currentSelecSourc.SourceName,
          state.currentSelecSourc.SourceNumber,
          state.currentSelecSourc.Format
        );
      } else if (item.keyType == "unselect" && !state.globallyLocked) {
        dispatch({ type: "unselectCurrentSelection" });
      } else if (item.keyType == "globalLock") {
        changeGlobalLockStatus();
      } else if (
        item.keyType == "toggleMV" &&
        !state.globallyLocked &&
        state.showMultiviewerInPanel
      ) {
        dispatch({
          type: "toggle_Multiviewer",
        });
      } else if (
        item.keyType == "moveRightDestinationGroup" &&
        !state.globallyLocked
      ) {
        let value = state.destinationTabsIndex;
        if (value <= state.distributions.length - 2) {
          value = value + 1;
        } else {
          value = 0;
        }
        dispatch({
          type: "changeDestinationTabsIndex",
          value: value,
        });
      } else if (
        item.keyType == "moveLeftDestinationGroup" &&
        !state.globallyLocked
      ) {
        let value = state.destinationTabsIndex;
        if (value == 0) {
          value = state.distributions.length - 1;
        } else {
          value = value - 1;
        }
        dispatch({
          type: "changeDestinationTabsIndex",
          value: value,
        });
      } else if (
        item.keyType == "moveRightSourceGroup" &&
        !state.globallyLocked
      ) {
        let value = state.sourceTabsIndex;
        if (value <= state.distributions.length - 2) {
          value = value + 1;
        } else {
          value = 0;
        }
        let streamChannel = getStreamChannel(value);
        dispatch({
          type: "changeSourceTabsIndex",
          value: value,
        });
        dispatch({
          type: "changeMultiviewerStream",
          value: streamChannel,
        });
      } else if (
        item.keyType == "moveLeftSourceGroup" &&
        !state.globallyLocked
      ) {
        let value = state.sourceTabsIndex;
        if (value == 0) {
          value = state.distributions.length - 1;
        } else {
          value = value - 1;
        }
        let streamChannel = getStreamChannel(value);
        dispatch({
          type: "changeSourceTabsIndex",
          value: value,
        });
        dispatch({
          type: "changeMultiviewerStream",
          value: streamChannel,
        });
      }
    }
  }

  function getStreamChannel(sourceGroupNumber) {
    let streamChannel = "MatchA";
    if (sourceGroupNumber === 0) {
      streamChannel = "MatchA";
      return streamChannel;
    } else if (sourceGroupNumber === 1) {
      streamChannel = "MatchB";
      return streamChannel;
    } else if (sourceGroupNumber === 2) {
      streamChannel = "MatchC";
      return streamChannel;
    }
  }

  function changeGlobalLockStatus() {
    let currentLocalStatus = state.globallyLocked;
    db.collection("users").doc(currentUser.uid).update({
      globallyLocked: !currentLocalStatus,
    });
  }

  function updateGlobalDarkMode() {
    let currentLocalStatus = state.darkModeGlobal;
    db.collection("users").doc(currentUser.uid).update({
      darkModeGlobal: !currentLocalStatus,
    });
  }

  function updateStandardRegion() {
    let currentLocalStatus = state.selectedRegion;
    db.collection("users").doc(currentUser.uid).update({
      region: currentLocalStatus,
    });
  }

  return (
    <div>
      {loading ? (
        <Box
          display="flex"
          justifyContent={"center"}
          style={{ paddingTop: "48vh" }}
        >
          <CircularProgress
            size="4rem"
            style={{ color: colors.darkColor1, opacity: "90%" }}
          />
        </Box>
      ) : (
        <ThemeProvider theme={theme}>
          <div
            style={{ outline: "none" }}
            onKeyDown={state.controllerEnabled ? handleKeyUp : null}
            tabIndex={0}
          >
            <CenteredTabs
              handleLogout={handleLogout}
              createTakeRequest={createTakeRequest}
              changeGlobalLockStatus={changeGlobalLockStatus}
              updateGlobalDarkMode={updateGlobalDarkMode}
              updateStandardRegion={updateStandardRegion}
              userEmail={currentUser.email}
            />
            {state.controllerSettingsEnabled ? null : (
              <Footer handleLogout={handleLogout}></Footer>
            )}
            <ErrorSnackbar></ErrorSnackbar>
          </div>
        </ThemeProvider>
      )}
    </div>
  );
}
