import React from "react";
import { InputBase, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { UserContext } from "../contexts/UserProvider";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { colors, fonts } from "./Theme";
import Button from "@material-ui/core/Button";
import { db } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from '@mui/icons-material/Save';
function DestinationRenamer(props) {
  const [state, dispatch] = React.useContext(UserContext);
  const [selectedDestination, setSelectedDestination] = React.useState("");
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [textFieldValue, setTextFieldValue] = React.useState("");
  const { currentUser } = useAuth();

  const useStyles = makeStyles((theme) => ({
    customTextfield: {
      borderBottom: `1px solid ${
        state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1
      }`,
      height: "50px",
      paddingLeft: "20px",
      paddingTop: "18px"
    },
    icon: {
      fill: state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1,
    },
    root: {
      fontFamily: `${fonts.medium} !important `,
      color: `${
        state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1
      } !important `,
      fontSize: `12px !important `,
      backgroundColor: ` ${
        state.darkModeEnabled ? colors.darkColor1 : colors.lightColor1
      } !important`,
    },
    selected: {
      color: `${
        state.darkModeEnabled ? colors.darkColor1 : colors.lightColor1
      } !important `,
      backgroundColor: ` ${
        state.darkModeEnabled ? colors.lightColor1 : colors.darkColor1
      } !important`,
    },
  }));
  const handleChange = (event) => {
    setSelectedDestination(event.target.value);
  };

  const handleChangeOnGroup = (event) => {
    setSelectedGroup(event.target.value);
  };

  const handleNewText = (event) => {
    setTextFieldValue(event.target.value);
  };

  function renameDestInFB() {
    let newDestinations = state.firebaseData.Destinations;
    if (textFieldValue != "") {
      newDestinations[selectedDestination].DestinationAlias = textFieldValue;
    }
    if (selectedGroup != "") {
      newDestinations[selectedDestination].Distribution = selectedGroup;
    }
  
    db.collection("users")
        .doc(currentUser.uid)
        .update({ Destinations: newDestinations })
        .then(function () {});
  }

  const classes = useStyles();

  return (
    <div style={{ marginTop: "20px"}}>
      <Box display="flex" justifyContent="left" flexWrap={"wrap"} gap={"10px"}>
        <Box sx={{ width: "250px", paddingRight: "20px"}}>
          <FormControl fullWidth style={{paddingTop: "2px"}}>
            <InputLabel
              style={{
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
                borderBlockColor: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              }}
              id="selectDestination"
            >
              Change destination
            </InputLabel>
            <Select
              variant="standard"
              labelId="selectDestination"
              id="selectDestination-id"
              value={selectedDestination}
              label="Destination"
              onChange={handleChange}
              style={{
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              }}
              sx={{
                ":before": {
                  borderBottomColor: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                },
                ":after": {
                  borderBottomColor: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                },
              }}
            >
              {state.firebaseData.Destinations.map((destination, index) => (
                <MenuItem
                  key={destination}
                  classes={{ selected: classes.selected, root: classes.root }}
                  value={index}
                  selected
                >
                  {destination.DestinationAlias}
                  {"  -  "}
                  {destination.Distribution}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          style={{
            paddingRight: "20px"}}
        >
          <InputBase
            required
            id="outlined-basic"
            inputProps={{
              maxLength: 15,
              style: {
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              },
            }}
            placeholder={"Type new name"}
            value={textFieldValue}
            onChange={handleNewText}
            variant="outlined"
            className={classes.customTextfield}
          />
        </Box>
        <Box
          sx={{
            width: "250px",
            paddingRight: "20px",
          }}
        >
          <FormControl fullWidth style={{paddingTop: "1px"}}>
            <InputLabel
              style={{
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
                borderBlockColor: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              }}
              id="selectGroup"
            >
              Preferred group
            </InputLabel>
            <Select
              variant="standard"
              labelId="selectGroup"
              id="selectGroup-id"
              value={selectedGroup}
              label="Destination Group"
              onChange={handleChangeOnGroup}
              style={{
                fontFamily: fonts.medium,
                color: state.darkModeEnabled
                  ? colors.lightColor1
                  : colors.darkColor1,
              }}
              sx={{
                ":before": {
                  borderBottomColor: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                },
                ":after": {
                  borderBottomColor: state.darkModeEnabled
                    ? colors.lightColor1
                    : colors.darkColor1,
                },
              }}
            >
              {state.distributions.map((distribution, index) => {
                return (
                  <MenuItem
                    key={distribution}
                    classes={{ selected: classes.selected, root: classes.root }}
                    value={distribution}
                    selected
                  >
                    {distribution}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            style={{
              minWidth: "10px",
              minHeight: "45px",
            }}
            onClick={() => {
              renameDestInFB();
            }}
          >
              <SaveIcon style={{paddingRight: "3px"}}></SaveIcon>
            <Typography>Save</Typography>
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default DestinationRenamer;
